
import { API_URL } from '@/constants/';
import axios from './axios';

export default {
  get({ current, pageSize, ...data }) {
    return axios.get(`${API_URL}/videos`, {
      params: { page: current, pagesize: pageSize, ...data },
    }).then((res) => ({
      data: res.data, total: res.headers['x-total'],
    }));
  },
  add(data) {
    return axios.post(`${API_URL}/videos`, data)
      .then((res) => res.data);
  },
  getDetail(id) {
    return axios.get(`${API_URL}/videos/${id}`)
      .then((res) => res.data);
  },
};
