<template>
  <div class="authority-content">
    <el-form :model="relationForm" label-width="125px" :inline="true"
             :rules="rules" ref="relationForm">
      <el-form-item label="用户" prop="userIds">
        <el-select v-model="relationForm.userIds" class="width-220" clearable multiple filterable>
          <el-option v-for="{ username, id } in userData" :key="id" :value="id" :label="username">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="margin-right-12">
        <el-select v-model="relationForm.type" class="width-125" placeholder="关联类型">
          <el-option v-for="(value, key) in typeObj" :key="key" :value="key" :label="value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="typeName" ref="typeName">
        <el-select v-model="relationForm.typeName" class="width-220" clearable multiple filterable>
          <el-option v-for="{ name, id } in obj" :key="`${name} - ${id}`" :value="id" :label="name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="relating" @click="relatedFn">关联</el-button>
      </el-form-item>
    </el-form>
    <el-form :model="searchForm" label-width="125px" :inline="true" ref="relationForm">
      <el-form-item label="用户">
        <el-input v-model="searchForm.userName" class="width-220"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="tableLoading" @click="searchFn">筛选</el-button>
      </el-form-item>
    </el-form>
    <el-tabs v-model="activeType">
      <el-tab-pane label="问卷类型关联" name="1"></el-tab-pane>
      <el-tab-pane label="视频类型关联" name="2"></el-tab-pane>
    </el-tabs>
    <el-table
      :data="tableData" v-loading="tableLoading"
      style="width: 100%; margin-bottom: 22px;">
      <el-table-column type="index" :index="setIndexFn" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="username" label="用户名称">
      </el-table-column>
      <el-table-column prop="name" label="问卷名称" v-if="this.activeType === '1'">
      </el-table-column>
      <el-table-column prop="name" label="视频名称" v-else>
      </el-table-column>
      <el-table-column prop="create_time" label="关联时间" width="150">
        <template slot-scope="scope">
          {{timStampToChar(scope.row.create_time)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="deleteFn(scope.row)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="pagination.current"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pagination.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
import users from '@/api/users';
import papers from '@/api/papers';
import videos from '@/api/videos';
import relations from '@/api/relations';
import { timStampToChar } from '@/utils/common';

export default {
  data() {
    const validateTypeName = (rule, value, callback) => {
      if (!value.length) {
        const { relationForm: { type }, typeObj } = this;
        callback(`请选择${typeObj[type]}`);
      } else callback();
    };
    return {
      relating: false,
      tableLoading: false,
      activeType: '1',
      userData: [],
      paperData: [],
      videoData: [],
      tableData: [],
      obj: [],
      typeObj: {
        1: '问卷',
        2: '视频',
      },
      relationForm: {
        userIds: [],
        type: '1',
        typeName: [],
      },
      searchForm: {
        userName: '',
      },
      rules: {
        userIds: [{
          required: true,
          message: '请选择用户',
          trigger: 'change',
        }],
        typeName: [{
          validator: validateTypeName,
          trigger: 'change',
        }],
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    'relationForm.type': function set(val) {
      this.$refs.typeName.resetField();
      if (val === '1') this.obj = this.paperData;
      else this.obj = this.videoData;
    },
    activeType() {
      this.searchInitFn();
    },
  },
  mounted() {
    this.searchUserFn();
    this.searchPaperFn();
    this.searchVideoFn();
    this.searchFn();
  },
  methods: {
    timStampToChar,
    searchInitFn() {
      this.pagination.current = 1;
      this.searchFn();
    },
    searchUserFn() {
      users.get({
        current: 1,
        pagesize: -1,
      }).then(({ data }) => {
        this.userData = data.data;
      });
    },
    searchPaperFn() {
      papers.get({
        current: 1,
        pagesize: -1,
      }).then(({ data }) => {
        this.paperData = data.data;
        this.obj = this.paperData;
      });
    },
    searchVideoFn() {
      videos.get({
        current: 1,
        pagesize: -1,
      }).then(({ data }) => {
        this.videoData = data.data;
      });
    },
    searchFn() {
      this.tableLoading = true;
      relations.get({
        type: Number(this.activeType),
        ...this.searchForm,
        ...this.pagination,
      }).then(({ data, total }) => {
        this.tableLoading = false;
        this.tableData = data.data;
        this.pagination.total = total ? Number(total) : 0;
      }).catch(() => {
        this.tableLoading = false;
        this.tableData = [];
        this.pagination.total = 0;
      });
    },
    relatedFn() {
      this.$refs.relationForm.validate((valid) => {
        if (valid) {
          const { type: stringType, userIds, typeName } = this.relationForm;
          const type = Number(stringType);
          const typeLabel = type === 1 ? 'paperIds' : 'videoIds';
          this.relating = true;
          relations.add({
            type,
            userIds,
            [typeLabel]: typeName,
          }).then(() => {
            this.relating = false;
            this.$message.success('关联创建成功');
            this.searchInitFn();
          }).catch(() => {
            this.relating = false;
            this.$message.success('关联创建失败');
          });
        }
      });
    },
    deleteFn({ username, name, id }) {
      this.$confirm(`确定要删除'${username}'用户与'${name}'的关联吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tableLoading = true;
        relations.delete({
          id,
          type: Number(this.activeType),
        }).then(() => {
          this.tableLoading = false;
          this.searchInitFn();
          this.$message.success('关联删除成功');
        }).catch(() => {
          this.tableLoading = false;
          this.$message.error('关联删除失败');
        });
      }).catch(() => {
        this.$message.info('已取消删除关联');
      });
    },
    setIndexFn(index) {
      const { current, pageSize } = this.pagination;
      return (current - 1) * pageSize + index + 1;
    },
    sizeChange(val) {
      this.pagination.current = 1;
      this.pagination.pageSize = val;
      this.searchFn();
    },
    currentChange(val) {
      this.pagination.current = val;
      this.searchFn();
    },
  },
};
</script>

<style scoped lang="less">
  .width-125 {
    width: 125px;
  }
  .margin-right-12 {
    margin-right: 12px;
  }
</style>
<style>
  .width-125 input {
    text-align: right;
  }
</style>
